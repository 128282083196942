@import "node_modules/react-modal-video/scss/modal-video.scss";

.menu-open {
  .menu {
    //transform: translateY(0) !important;
    display: flex;
    width: 85% !important;
    background-color: white;
    position: fixed;
    transform: translateX(0) !important;
    height: 100vh !important;
    margin-top: 0;
    // justify-content: center;
    // align-items: center;
    right: 0;
    ul {
      //   height: 50%;
      opacity: 1 !important;
    }
  }
  span {
    background-color: transparent !important;
    &::before {
      top: 0 !important;
      transform: rotate(-45deg);
      background-color: black !important;
    }
    &::after {
      bottom: 0 !important;
      transform: rotate(45deg);
      background-color: black !important;
    }
  }
}
